import {
  faFacebookSquare,
  faLinkedin,
  faGooglePlusSquare
} from "@fortawesome/free-brands-svg-icons"
import { faExclamationCircle, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import {
  Button,
  Carousel,
  Col,
  Container,
  Jumbotron,
  Row,
  Spinner,
} from "react-bootstrap"
import * as styles from "./index.module.scss"
import Layout from "../components/layout"
import OurServices from "../components/our-services"
import constants from "../constants"

export default function Home() {
  const [googleReviews, setGoogleReviews] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetch(`${process.env.API_URL}/googleReviews`)
      .then(res => (res.ok ? res.json().then(setGoogleReviews) : null))
      .then(() => setIsLoading(false))
  }, [])

  return (
    <Layout>
      <Jumbotron
        className="align-items-center bg-secondary d-md-flex mb-0 p-4 text-center"
        fluid
      >
        <Container className="mr-xl-0 px-2 w-auto">
          <h1 className="font-weight-bold h5" id={styles.title}>
            Mark Sterling <span className="text-light-green">Gober</span>, LLC
          </h1>
          <h3>Attorney At Law</h3>
          <h2 className="h6" id={styles.subtitle}>
            <span className="text-light-green">Not</span> Your Typical Law Firm
          </h2>
          <Button
            className="align-items-center d-flex mt-3 mx-auto bg-light-green"
            id={styles.button}
            variant="light"
          >
            <FontAwesomeIcon icon={faPhone} size="2x" />
            <Container>
              <a className="text-dark" href="tel:205-366-1370">
                CALL TO SCHEDULE A CONSULTATION
              </a>
            </Container>
          </Button>
          <Row className="d-inline-block mt-3">
            <Col xs={12}>
              <Row className="justify-content-center">
                <Link className="mx-2" to={constants.googleUrl}>
                  <FontAwesomeIcon
                    icon={faGooglePlusSquare}
                    id={styles.twitterIcon}
                    size="4x"
                  />
                </Link>
                <Link className="mx-2" to={constants.linkedinUrl}>
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    id={styles.linkedinIcon}
                    size="4x"
                  />
                </Link>
                <Link className="mx-2" to={constants.aavoUrl}>
                  <div
                    className="d-inline-block p-2 position-relative rounded"
                    id={styles.aavoIcon}
                  >
                    <StaticImage
                      alt="Avvo"
                      className="d-block"
                      placeholder="blurred"
                      src="../images/aavo.svg"
                      width={41}
                    />
                  </div>
                </Link>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className="mt-3 mt-md-0 w-auto">
          <StaticImage
            alt="Mark Gober"
            id={styles.image}
            placeholder="blurred"
            src="../images/home-transformed.webp"
            width={600}
          />
        </Container>
      </Jumbotron>
      <OurServices />
      <Container className="bg-secondary mb-0 p-5 text-center">
        <Row xs={12} className="align-items-center">
          <Col xs={12} xl={6}>
            <h1 className="fw-bold h4">
              Our Clients Are Talking, Heres What They Have To Say:
            </h1>
            {googleReviews ? (
              <Carousel
                className="mt-4 pb-5 px-lg-3 px-xl-5"
                controls={false}
                id={styles.carousel}
                indicators={true}
              >
                {googleReviews.map((review, i) => (
                  <Carousel.Item key={i}>
                    <figure>
                      <blockquote className={`blockquote ${styles.reviewText}`}>
                        <p>"{review.text}"</p>
                      </blockquote>
                      <figcaption className="blockquote-footer text-dark">
                        {review.author}
                      </figcaption>
                    </figure>
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : isLoading ? (
              <Spinner animation="border" className="mt-2 light-green" />
            ) : (
              <Container className="mt-3 text-danger">
                <FontAwesomeIcon icon={faExclamationCircle} size="3x" />
                <p className="mt-2">
                  Oops! There was a problem loading Google reviews.
                </p>
              </Container>
            )}
          </Col>
          <Col xs={12} xl={6} className="mt-3 mt-xl-0">
            <StaticImage
              alt="office"
              id={styles.image}
              placeholder="blurred"
              src="../images/office2.jpeg"
              width={800}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
